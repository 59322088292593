import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/wamp/www/docz/sgc-documentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "typescript-docz-example"
    }}>{`Typescript Docz example`}</h1>
    <h2 {...{
      "id": "using-create-docz-app"
    }}>{`Using `}<inlineCode parentName="h2">{`create-docz-app`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npx create-docz-app docz-app-typescript --example typescript
# or
yarn create docz-app docz-app-typescript --example typescript
`}</code></pre>
    <h2 {...{
      "id": "download-manually"
    }}>{`Download manually`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl https://codeload.github.com/doczjs/docz/tar.gz/master | tar -xz --strip=2 docz-master/examples/typescript
mv typescript docz-typescript-example
cd docz-typescript-example
`}</code></pre>
    <h2 {...{
      "id": "setup"
    }}>{`Setup`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn # npm i
`}</code></pre>
    <h2 {...{
      "id": "run"
    }}>{`Run`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn dev # npm run dev
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      